import ENVPATH from "./envpaths";

const MODULE_PATH = {
  ACCOUNTS: "/accounts",
  PAYMENTS: "/payments",
  kYC: "/kyc",
  REPORTS: "/reports",
  MANAGE_ACCOUNT: "/configurations",
  FINANCIAL_SERVICES: "/bytes",
  CONFIGURATIONS: "/configuration",
  // RECURRING_COLLECTION: '/recurring-collection',
  OTHERS: "/others",
};

const SUBMODULE_PATH = {
  ACCOUNTS_ALL_ACCOUNTS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.ACCOUNTS + "/all-accounts",
  ACCOUNTS_TRANSACTIONS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.ACCOUNTS + "/transactions",
  ACCOUNTS_BALANCES:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.ACCOUNTS + "/account-balances",
  ACCOUNTS_ANALYTICS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.ACCOUNTS + "/analytics",
  ACCOUNTS_REPORTS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.ACCOUNTS + "/reports",
  // VIEW_CREDITS: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.ACCOUNTS + '/view_credits',
  PAYMENTS_TRANSACTIONS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/transactions",
  PAYMENTS_PAYOUTS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/payouts",
  // * --BULK_CAPABILITY: Uncomment when required. Current Status: Is being used.
  BULK_CAPABILITIES:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/bulk-capabilities",
  PAYMENTS_PAYMENT_LINKS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/payment-links",
  PAYMENTS_MANAGE_BENEFICIARY:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/manage-beneficiaries",
  PAYMENTS_COLLECTION_REQUESTS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/collection-requests",
  // PAYMENTS_COLLECTION_SETTLEMENTS:
  // ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/settlements",
  PAYMENTS_VALIDATE_ACCOUNTS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/validate-bank-account",
  PAYMENTS_ENACH: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/enach",
  PAYMENTS_UPI_AUTOPAY:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/upi-autopay",
  PAYMENTS_ANALYTICS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + "/analytics",
  // PAYMENTS_CREDITS: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.PAYMENTS + '/view_credits',
  KYC_VALIDATE: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/validate",
  KYC_ANDHAAR_XML: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/aadhaar",
  CKYC_SEARCH_AND_DOWNLOAD:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/ckyc-search-and-download",
  KYC_FACEMATCH: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/facematch",
  KYC_TEXTMATCH: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/textmatch",
  KYC_ID_CLASSIFICATION:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/id-classification",
  KYC_PHOTOCOPY_CHECK:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/photocopy-check",
  KYC_IMAGE_QUALITY:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/image-quality",
  KYC_LIVELINESS_CHECK:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/liveness-check",
  KYC_AADHAAR_UID_MASKING:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/aadhaar-masking",
  KYC_DIGILOCKER: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/digilocker",
  // KYC_CREDITS: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + '/view_credits',
  KYC_OCR: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/ocr",
  KYC_ANALYTICS: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.kYC + "/analytics",

  REPORTS_NEOBANKING:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.REPORTS + "/neobanking",
  FINANCIAL_SERVICES_CREDIT_REPORT:
    ENVPATH.DASHBOARD_STAGING +
    MODULE_PATH.FINANCIAL_SERVICES +
    "/credit-reports",
  // FINANCIAL_SERVICES_DATA_PULL: ENVPATH.DASHBOARD_STAGING + MODULE_PATH.FINANCIAL_SERVICES + '/data-pull',
  FINANCIAL_SERVICES_ANALYTICS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.FINANCIAL_SERVICES + "/analytics",
  FINANCIAL_SERVICES_CREDIT_REPORT:
    ENVPATH.DASHBOARD_STAGING +
    MODULE_PATH.FINANCIAL_SERVICES +
    "/credit-reports",
  CONFIGURATION_CALLBACKS:
    ENVPATH.DASHBOARD_STAGING +
    MODULE_PATH.MANAGE_ACCOUNT +
    "/callback-configuration",
  MANAGE_IP:
    ENVPATH.DASHBOARD_STAGING +
    MODULE_PATH.MANAGE_ACCOUNT +
    "/manage-ips",
  REPORTS_CAS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.REPORTS + "/generate-reports",
  DOWNLOAD_REPORTS:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.REPORTS + "/download-reports",
  REPORTS_CREDIT_HISTORY:
    ENVPATH.DASHBOARD_STAGING + MODULE_PATH.REPORTS + "/credit-history",
};

export default SUBMODULE_PATH;
